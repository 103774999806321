import apiFetch from './apiFetch'
import { graphqlApi } from './graphQL'
import {
  GqlTagDetailsFragment,
  GqlUpdatedTagFragment,
  GqlUpdateTagInput,
} from './graphqlTypes'
import { TagDto, TagUpdate } from './types'

export async function getTags(factory: string): Promise<TagDto[]> {
  const response = await apiFetch({ path: '/tags', query: { factory } })
  return response.json()
}

export async function updateTags(
  factory: string,
  updatedTags: TagUpdate[],
): Promise<void> {
  await apiFetch({
    path: '/tags/multiple',
    method: 'PUT',
    body: JSON.stringify(updatedTags),
    query: { factory },
  })
}

export async function updateTag(
  input: GqlUpdateTagInput,
): Promise<GqlUpdatedTagFragment | null | undefined> {
  const api = await graphqlApi()
  const response = await api.UpdateTag({ input })

  return response.updateTag?.tag
}

export async function getTag(
  factory: string,
  tagName: string,
): Promise<GqlTagDetailsFragment | null | undefined> {
  const api = await graphqlApi()
  const response = await api.FetchTag({
    factory,
    tagName,
  })
  return response.factory?.tagByName
}
