import { light } from '@fortawesome/fontawesome-svg-core/import.macro'
import { ErrorInfo, useEffect, useState } from 'react'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { ErrorBoundary, FallbackProps } from 'react-error-boundary'
import { useMySites } from 'orgs-sites/site/api'
import { ErrorDisplay } from 'pages/app'
import {
  useFactoryTrendViews,
  useCreateFactoryTrendView,
  useUpdateFactoryTrendView,
} from 'trend/api'
import { useSite } from 'src/contexts/site'
import { triggerChartRedraw, useTitle } from 'src/utility'
import { TimeRangeProvider } from 'src/contexts/timeRange'
import { CursorProvider } from 'src/contexts/cursor'
import {
  ChartOptions,
  ChartSize,
  ChartType,
  TableComponentLayout,
} from 'src/types/chartTypes'
import { ChartsProvider, nextChartId, useCharts } from 'src/contexts/charts'
import { logError } from 'src/utility/logging'
import { useAuth } from 'src/contexts/auth'
import { useNavigationContext } from 'src/contexts/navigation'
import { TimePickerNavigation } from 'src/components/ui'
import {
  ForecastsTable,
  AnomalyModelsTable,
  TagsTable,
  AddChartModal,
  SaveTrendViewModal,
  TrendLayout,
  LayoutSizePicker,
  ChartTypePicker,
  ShareDropdown,
  TrendNavigation,
  TrendMenu,
  UnsavedChangesModal,
  ShareButton,
  Dropzone,
} from './components'
import { TrendLandingPage } from './TrendLandingPage'
import { ModalState, SaveTrendProps } from './trend.types'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { TrendProvider } from './trend.state'

const TrendWithLayout = (): JSX.Element => {
  useTitle('Trend')
  const [modalState, setModalState] = useState(ModalState.None)
  const {
    chartConfig: { selectedSize, isFullWidth },
    charts,
    isViewChanged,
    addChart: addChartToContext,
    setChartOrder,
    setChartConfig,
    resetViewChanges,
    loadClearView,
  } = useCharts()
  const [chartType, setChartType] = useState(ChartType.TimeSeries)
  const tabs: { [key: string]: TableComponentLayout } = {
    Tags: TagsTable,
  }
  const { data: views, refetch: refetchViews } = useFactoryTrendViews()
  const { mutateAsync: createTrendView } = useCreateFactoryTrendView()
  const { mutateAsync: saveEditedView } = useUpdateFactoryTrendView()
  const { data: mySites } = useMySites()

  const navigate = useNavigate()
  const { trendViewId } = useParams()
  const { viewer } = useAuth()

  const { setTitleComponent } = useNavigationContext()

  const { isWorkshop, rootLink, id: factory } = useSite()

  if (!isWorkshop) {
    tabs.Forecasts = ForecastsTable
    tabs['Anomaly Models'] = AnomalyModelsTable
  }

  useEffect(() => {
    setTitleComponent(
      <TrendNavigation
        views={views ?? []}
        viewId={trendViewId}
        charts={charts}
        isViewChanged={isViewChanged}
      />,
    )
    return () => setTitleComponent(null)
  }, [setTitleComponent, trendViewId, views, charts, isViewChanged])

  useEffect(() => {
    triggerChartRedraw()
  }, [charts])

  const addNewChart = (chart: ChartOptions): void => {
    addChartToContext(chart)
  }

  const handleSizeChange = (size: ChartSize): void => {
    setChartConfig({ isFullWidth, selectedSize: size })
  }

  const handleIsFullWidthChange = (isFullWidth: boolean): void => {
    setChartConfig({ isFullWidth, selectedSize })
  }

  const onChartTypeClick = (type: ChartType): void => {
    setChartType(type)
    setModalState(ModalState.Add)
  }

  async function handleSaveView({
    name,
    shared,
    duplicate = false,
    clearAfterSave = false,
  }: SaveTrendProps): Promise<void> {
    if (trendViewId && !duplicate) {
      const response = await saveEditedView({
        name,
        shared,
        trendViewId,
        data: JSON.stringify({
          charts,
          config: { isFullWidth, selectedSize },
        }),
      })

      if (response.id) {
        if (clearAfterSave) {
          loadClearView()
          return
        }
        navigate(`${rootLink}/trend/${response.id}`)
      }
    } else {
      const response = await createTrendView({
        name,
        shared,
        factoryId: factory,
        data: JSON.stringify({
          charts,
          config: { isFullWidth, selectedSize },
        }),
      })
      if (response.id) {
        if (clearAfterSave) {
          loadClearView()
          return
        }
        navigate(`${rootLink}/trend/${response.id}`)
      }
    }
    refetchViews()
    setModalState(ModalState.None)
  }

  async function handleClearView(): Promise<void> {
    if (isViewChanged) {
      setModalState(ModalState.Unsaved)
    } else {
      loadClearView()
    }
  }

  async function handleSaveChanges({
    name,
    shared,
  }: SaveTrendProps): Promise<void> {
    const currentView = views?.find(v => v.id === trendViewId)
    const duplicate: boolean =
      name !== currentView?.name ||
      (currentView && viewer.id !== currentView.user.id)
    handleSaveView({ name, shared, duplicate, clearAfterSave: true })
    setModalState(ModalState.None)
  }

  return (
    <TrendProvider>
      <TimeRangeProvider urlQuery>
        <DndProvider backend={HTML5Backend}>
          <CursorProvider>
            <div className="flex h-[calc(100vh-50px)] flex-col gap-xs py-s pt-0">
              <div className="flex flex-wrap items-center justify-between gap-s px-s">
                <div className="flex items-center gap-xs">
                  <ChartTypePicker
                    onClick={() => onChartTypeClick(ChartType.TimeSeries)}
                    chartType={ChartType.TimeSeries}
                    setChartType={onChartTypeClick}
                  />
                  <LayoutSizePicker
                    selectedSize={selectedSize}
                    setSelectedSize={handleSizeChange}
                    isFullWidth={isFullWidth}
                    setIsFullWidth={handleIsFullWidthChange}
                    disabled={charts.length < 2}
                  />
                  <div className="relative">
                    <TrendMenu
                      menuItems={[
                        {
                          label: 'Save',
                          icon: light('floppy-disk'),
                          onClick: () => setModalState(ModalState.Save),
                          isVisible: true,
                          isDisabled: charts.length < 1,
                        },
                        {
                          label: 'Share',
                          icon: light('share-from-square'),
                          onClick: () => setModalState(ModalState.Share),
                        },
                        {
                          label: 'Reset changes',
                          icon: light('undo'),
                          onClick: resetViewChanges,
                          isVisible: !!trendViewId,
                          isDisabled: !isViewChanged,
                          hasBorder: true,
                        },
                        {
                          label: 'New',
                          icon: light('plus'),
                          onClick: handleClearView,
                          hasBorder: true,
                        },
                      ]}
                    />
                    <ShareDropdown
                      isOpen={modalState === ModalState.Share}
                      setIsOpen={isOpen => {
                        if (isOpen) {
                          setModalState(ModalState.Share)
                        } else {
                          setModalState(ModalState.None)
                        }
                      }}
                    />
                  </div>
                </div>
                <TimePickerNavigation />
              </div>
              <div className="relative flex flex-1 overflow-hidden">
                {charts.length === 0 ? (
                  <TrendLandingPage onButtonClick={onChartTypeClick} />
                ) : (
                  <>
                    <TrendLayout
                      charts={charts}
                      setChartOrder={setChartOrder}
                      selectedSize={selectedSize}
                      isFullWidth={isFullWidth}
                    />
                    <Dropzone />
                  </>
                )}
                {modalState === ModalState.Add && (
                  <AddChartModal
                    isEdit={false}
                    close={() => setModalState(ModalState.None)}
                    onAddChart={addNewChart}
                    chartType={chartType}
                    id={nextChartId(charts)}
                  />
                )}
                {modalState === ModalState.Save && (
                  <SaveTrendViewModal
                    isModalOpen={modalState === ModalState.Save}
                    onClose={() => setModalState(ModalState.None)}
                    siteName={
                      mySites?.find(s => s.id === factory)?.name ??
                      'this factory'
                    }
                    view={views?.find(v => v.id === trendViewId)}
                    onSaveAs={({ name, shared }) =>
                      handleSaveView({
                        name,
                        shared,
                        duplicate: true,
                      })
                    }
                    onSave={handleSaveView}
                    isViewChanged={isViewChanged}
                    canEdit={
                      !trendViewId ||
                      views?.find(v => v.id === trendViewId)?.user.id ===
                        viewer.id
                    }
                    currentUserId={viewer.id}
                  />
                )}

                <UnsavedChangesModal
                  isModalOpen={modalState === ModalState.Unsaved}
                  onCancel={() => setModalState(ModalState.None)}
                  onDiscard={() => {
                    setModalState(ModalState.None)
                    loadClearView()
                  }}
                  view={views?.find(v => v.id === trendViewId)}
                  onSave={handleSaveChanges}
                  canEdit={
                    !trendViewId ||
                    views?.find(v => v.id === trendViewId)?.user.id ===
                      viewer.id
                  }
                  currentUserId={viewer.id}
                />
              </div>
            </div>
          </CursorProvider>
        </DndProvider>
      </TimeRangeProvider>
    </TrendProvider>
  )
}

function Fallback({ error, resetErrorBoundary }: FallbackProps): JSX.Element {
  return (
    <ErrorDisplay
      message="Error loading charts"
      error={error}
      action={resetErrorBoundary}
      actionTitle="Return to trend"
    />
  )
}

function onError(error: Error, errorInfo: ErrorInfo): void {
  logError(error, {
    source: 'trend',
    errorInfo,
  })
}

export function TrendPage(): JSX.Element {
  const [searchParams, setSearchParams] = useSearchParams()
  const { id: currentFactory } = useSite()

  return (
    <ErrorBoundary
      FallbackComponent={Fallback}
      onError={onError}
      resetKeys={[searchParams]}
      onReset={() => setSearchParams([])}
    >
      <ChartsProvider id={currentFactory}>
        <TrendWithLayout />
      </ChartsProvider>
    </ErrorBoundary>
  )
}
