import { useEffect } from 'react'
import Color from 'color'
import useTimeRange from 'src/contexts/timeRange'
import { useTheme } from 'styled-components'
import HighchartsReact from 'highcharts-react-official'
import { anomalyDisplayTimeRange, TimeRange, timeRangeOverlap } from 'src/types'
import { GqlFetchAnomalyFragment } from 'src/services'
import { parseISO } from 'date-fns'
import { useChartWidth } from './useChartWidth'
import { useChartHeight } from './useChartHeight'

interface UseAnomaly {
  ref: React.RefObject<HighchartsReact.RefObject>
  anomaly?: GqlFetchAnomalyFragment
}

export function useAnomaly({ ref, anomaly }: UseAnomaly): void {
  const { timeRange } = useTimeRange()
  const width = useChartWidth(ref)
  const height = useChartHeight(ref)
  const theme = useTheme()

  useEffect(() => {
    function getDisplayRange(): TimeRange | undefined {
      if (anomaly) {
        const range = anomalyDisplayTimeRange({
          from: parseISO(anomaly.start).valueOf(),
          inProgress: anomaly.inProgress,
          to: anomaly.end ? parseISO(anomaly.end).valueOf() : undefined,
        })
        return timeRangeOverlap(range, timeRange)
      }
      return undefined
    }

    const overlap = getDisplayRange()

    if (overlap && ref.current) {
      const { xAxis, plotTop, plotHeight, renderer } = ref.current.chart
      const begin = xAxis[0].toPixels(overlap.from, false)
      const stop = xAxis[0].toPixels(overlap.to, false)

      const area = renderer
        .rect(begin, plotTop, stop - begin, plotHeight)
        .attr({
          fill: Color(theme.colors.danger).alpha(0.6).toString(),
        })
        .add()

      return () => area.destroy()
    }
    // empty cleanup
    return () => {}
  }, [ref, width, height, timeRange, anomaly, theme.colors.danger])
}
