import { graphqlApi } from './graphQL'
import {
  GqlAddAnomalyCommentInput,
  GqlAnomalyBaseFragment,
  GqlAnomalyCommentBaseFragment,
  GqlAnomalyCommentFragment,
  GqlAnomalyCountByStateQueryVariables,
  GqlAnomalyState,
  GqlFetchAnomalyFragment,
  GqlFetchAnomalyQuery,
  GqlSetAnomalyStateInput,
} from './graphqlTypes'
import { isDefined } from 'src/types'

export type StateCount = {
  state: GqlAnomalyState
  count: number
}

export async function fetchAnomalyCountByState(
  vars: GqlAnomalyCountByStateQueryVariables,
): Promise<StateCount[]> {
  const api = await graphqlApi()
  const response = await api.AnomalyCountByState(vars)
  return response.factory?.anomalyCountByState ?? []
}

export async function fetchAnomalies(
  factory: string,
  from: string,
  to: string,
): Promise<GqlAnomalyBaseFragment[]> {
  const api = await graphqlApi()
  const res = await api.FetchAnomalies({ factory, from, to })
  return (
    res.factory?.anomalies?.edges?.map(e => e?.node).filter(isDefined) ?? []
  )
}

export async function fetchAnomaly(
  factory: string,
  anomaly: string,
): Promise<GqlFetchAnomalyQuery> {
  const api = await graphqlApi()
  const res = await api.FetchAnomaly({ factory, anomaly })
  return res
}

export async function fetchLatestAnomalyComments(
  factory: string,
): Promise<GqlAnomalyCommentFragment[]> {
  const api = await graphqlApi()
  const res = await api.FetchLatestComments({ factory })
  return res.factory?.latestAnomalyComments ?? []
}

export async function addAnomalyComment(
  input: GqlAddAnomalyCommentInput,
): Promise<GqlAnomalyCommentBaseFragment | undefined | null> {
  const api = await graphqlApi()
  const res = await api.AddAnomalyComment({ input })
  return res.addAnomalyComment?.comment
}

export async function setAnomalyState(
  input: GqlSetAnomalyStateInput,
): Promise<GqlFetchAnomalyFragment | undefined | null> {
  input.state = input.state.replaceAll(' ', '_') as GqlAnomalyState
  const api = await graphqlApi()
  const res = await api.SetAnomalyState({ input })
  return res.setAnomalyState?.anomaly
}
