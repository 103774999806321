import { Site, isDefined, siteRoleFromGql } from 'src/types'
import { graphqlApi } from './graphQL'
import {
  GqlFactoryRole,
  GqlRemoveFactoryUserMutation,
  GqlSetFactoryUserRoleMutation,
  GqlFactoryInvitation,
  GqlFetchFactoryCreditsQuery,
  GqlFetchMyFactoriesQuery,
  GqlFetchFactoryDexAccessQuery,
  GqlFactoryTrendViewFragment,
  GqlUpdateTrendViewInput,
  GqlDeleteTrendViewInput,
  GqlGatewayFragment,
  GqlUpdateUserDefaultFactoryInput,
  GqlCreateOpcConnectionInput,
  GqlOpcConnectionFragment,
  GqlUpdateOpcConnectionInput,
  GqlOpcBrowseQueryVariables,
  GqlBrowseNodeFragment,
  GqlNodeValuesFragment,
} from './graphqlTypes'

interface FactoryID {
  factory: string
}

export type SiteUser = {
  name: string | null | undefined
  id: string
  role: GqlFactoryRole
  email?: string | null | undefined
  status?: string
  addedBy?: string
  homeOrganization?: {
    id?: string
    name?: string
  }
  userTag?: 'Home' | 'Verified'
}

export async function fetchFactory(id: string): Promise<Site | null> {
  const api = await graphqlApi()
  const data = await api.FetchFactory({ id })

  const f = data.factory
  return f
    ? {
        id: f.id,
        name: f.name,
        orgName: f.customer.name,
        orgId: f.customer.id,
        viewerRole: f.viewerRole ? siteRoleFromGql(f.viewerRole) : undefined,
        numOfGateways: f.agents?.items?.length ?? 0,
        numOfUsers: f.userCount,
      }
    : null
}

export async function fetchFactoryUsers({
  factory,
}: FactoryID): Promise<SiteUser[]> {
  const api = await graphqlApi()
  const data = await api.FetchFactoryUsers({
    factory,
  })
  const users: SiteUser[] = []
  if (data.factory?.users?.edges) {
    data.factory.users.edges.forEach(edge => {
      if (edge && edge.node) {
        users.push({
          name: edge.node.name,
          id: edge.node.id,
          role: edge.role,
          email: edge.node.email,
          status: edge.node.status,
          addedBy: edge.node.createdBy?.name,
          homeOrganization: {
            id: edge.node.customer?.id,
            name: edge.node.customer?.name,
          },
          userTag:
            edge.node.customer?.id === data.factory?.customer.id
              ? 'Home'
              : 'Verified',
        })
      }
    })
  }
  return users
}

interface UserInput {
  userId: string
  factoryId: string
  role: GqlFactoryRole
}

export async function setUserRole({
  userId,
  factoryId,
  role,
}: UserInput): Promise<GqlSetFactoryUserRoleMutation> {
  const api = await graphqlApi()
  const data = await api.SetFactoryUserRole({
    input: {
      userId,
      factoryId,
      role,
    },
  })
  return data
}

interface RemoveUserInput {
  userId: string
  factoryId: string
}

export async function removeUser({
  userId,
  factoryId,
}: RemoveUserInput): Promise<GqlRemoveFactoryUserMutation> {
  const api = await graphqlApi()
  const data = await api.RemoveFactoryUser({
    input: {
      userId,
      factoryId,
    },
  })
  return data
}

export async function fetchFactoryGateways({
  factory,
}: FactoryID): Promise<GqlGatewayFragment[]> {
  const api = await graphqlApi()

  const data = await api.FetchFactoryAgents({
    factory,
  })
  const gateways: GqlGatewayFragment[] = []
  if (data.factory?.agents?.edges) {
    data.factory.agents.edges.forEach(edge => {
      if (edge && edge.node) {
        gateways.push(edge.node)
      }
    })
  }
  return gateways
}

export async function fetchFactoryGateway(
  factory: string,
  agent: string,
): Promise<GqlGatewayFragment | undefined | null> {
  const api = await graphqlApi()
  const data = await api.FetchFactoryAgent({
    factory,
    agent,
  })
  return data.factory?.agent
}

export async function fetchFactoryInvitations({
  factory,
}: FactoryID): Promise<GqlFactoryInvitation[]> {
  const api = await graphqlApi()

  const data = await api.FetchFactoryInvitations({
    factory,
  })
  const invitations: any[] = []
  if (data.factory?.invitations?.edges) {
    data.factory.invitations.edges.forEach(edge => {
      if (edge && edge.node) {
        invitations.push({
          id: edge.node.id,
          role: edge.node.role,
          createdAt: edge.node.createdAt,
          completedAt: edge.node.completedAt,
          validUntil: edge.node.validUntil,
          state: edge.node.state,
          invitee: edge.node.invitee,
          inviter: edge.node.inviter,
          customer: edge.node.factory.customer,
        })
      }
    })
  }
  return invitations
}

export async function fetchFactoryCredits({
  factory,
}: FactoryID): Promise<GqlFetchFactoryCreditsQuery> {
  const api = await graphqlApi()

  const data = await api.FetchFactoryCredits({
    factory,
  })
  return data
}

export async function fetchMyFactories(): Promise<GqlFetchMyFactoriesQuery> {
  const api = await graphqlApi()
  const data = await api.FetchMyFactories()
  return data
}

export async function fetchFactoryDexAccess({
  factory,
}: FactoryID): Promise<GqlFetchFactoryDexAccessQuery> {
  const api = await graphqlApi()
  const data = await api.FetchFactoryDexAccess({
    factory,
  })
  return data
}

export async function fetchFactoryTrendViews({
  factory,
}: FactoryID): Promise<GqlFactoryTrendViewFragment[]> {
  const api = await graphqlApi()
  const data = await api.FetchFactoryTrendViews({
    factory,
  })
  return data.factory?.trendViews?.items?.filter(isDefined) ?? []
}
type CreateTrendViewInput = {
  factoryId: string
  name: string
  data: string
  shared: boolean
}

export async function createTrendView({
  factoryId,
  name,
  data,
  shared,
}: CreateTrendViewInput): Promise<GqlFactoryTrendViewFragment | undefined> {
  const api = await graphqlApi()
  const result = await api.createTrendView({
    input: {
      factoryId,
      name,
      data,
      shared,
    },
  })
  return result.createTrendView?.trendView
}

export async function updateTrendView(
  input: GqlUpdateTrendViewInput,
): Promise<GqlFactoryTrendViewFragment | undefined> {
  const api = await graphqlApi()
  const result = await api.updateTrendView({
    input,
  })
  return result.updateTrendView?.trendView
}

export async function deleteTrendView(
  input: GqlDeleteTrendViewInput,
): Promise<GqlFactoryTrendViewFragment | undefined> {
  const api = await graphqlApi()
  const result = await api.deleteTrendView({
    input,
  })
  return result.deleteTrendView?.trendView
}

export async function updateDefaultFactory(
  input: GqlUpdateUserDefaultFactoryInput,
): Promise<void> {
  const api = await graphqlApi()
  await api.UpdateUserDefaultFactory({
    input,
  })
}

export async function createOpcUaConnection(
  input: GqlCreateOpcConnectionInput,
): Promise<GqlOpcConnectionFragment | undefined> {
  const api = await graphqlApi()
  const result = await api.createOpcConnection({
    input,
  })
  return result.createOpcConnection?.connection
}

export async function updateOpcUaConnection(
  input: GqlUpdateOpcConnectionInput,
): Promise<GqlOpcConnectionFragment | undefined> {
  const api = await graphqlApi()
  const result = await api.updateOpcConnection({
    input,
  })
  return result.updateOpcConnection?.connection
}

export async function fetchAgentOpcConnections(
  factory: string,
  agent: string,
): Promise<GqlOpcConnectionFragment[]> {
  const api = await graphqlApi()
  const data = await api.FetchOpcConnections({
    factory,
    agent,
  })
  return data.factory?.agent?.opcConnections?.items?.filter(isDefined) ?? []
}

export async function browseOpcConnection(
  connectionId: string,
  nodeId: string,
  timeout = 15000,
): Promise<GqlBrowseNodeFragment | undefined> {
  const api = await graphqlApi()
  const data = await api.OpcBrowse({
    connectionId,
    nodeId,
    timeout,
  })
  if (data.node?.__typename !== 'OpcConnection') return undefined
  return data.node.browse
}

export async function fetchNodeValues(
  connectionId: string,
  nodeIds: string[],
  timeout = 15000,
): Promise<GqlNodeValuesFragment[] | undefined> {
  const api = await graphqlApi()
  const data = await api.ReadNodeValues({
    connectionId,
    nodeIds,
    timeout,
  })
  if (data.node?.__typename !== 'OpcConnection') return undefined

  return data.node.readValues
}
