import apiFetch from './apiFetch'
import {
  InternalAsset,
  HierarchyAsset,
  UpdateAssetDto,
  CreateAssetDto,
  CreateHierarchyAssetDto,
  UpdateHierarchyAssetDto,
} from './types'

export async function getHierarchyAssets(
  factory: string,
): Promise<HierarchyAsset[]> {
  const response = await apiFetch({
    path: '/internal/internalAssetHierarchy',
    query: {
      factory,
    },
  })
  return response.json()
}

export async function getAssets(
  factory: string,
  inactive = true,
): Promise<InternalAsset[]> {
  const response = await apiFetch({
    path: '/internal/internalAssets',
    query: {
      inactive,
      view: 0,
      factory,
    },
  })
  return response.json()
}

export async function updateHierarchyAsset(
  updatedAsset: UpdateHierarchyAssetDto,
): Promise<void> {
  await apiFetch({
    path: '/internal/internalAssetHierarchy',
    method: 'PUT',
    body: JSON.stringify(updatedAsset),
  })
}

export async function updateAsset(updatedAsset: UpdateAssetDto): Promise<void> {
  await apiFetch({
    path: '/internal/internalAssets',
    method: 'PUT',
    body: JSON.stringify(updatedAsset),
  })
}

export async function createHierarchyAsset(
  newAsset: CreateHierarchyAssetDto,
  factory: string,
): Promise<HierarchyAsset> {
  const response = await apiFetch({
    path: '/internal/internalAssetHierarchy',
    method: 'POST',
    body: JSON.stringify(newAsset),
    query: {
      factory,
    },
  })
  return response.json()
}

export async function createAsset(
  newAsset: CreateAssetDto,
  factory: string,
): Promise<InternalAsset> {
  const response = await apiFetch({
    path: '/internal/internalAssets',
    method: 'POST',
    body: JSON.stringify(newAsset),
    query: {
      factory,
    },
  })
  return response.json()
}

export async function deleteHierarchyAsset(id: number): Promise<void> {
  await apiFetch({
    path: `/internal/internalAssetHierarchy/${id}`,
    method: 'DELETE',
  })
}

export async function deleteAsset(id: number): Promise<void> {
  await apiFetch({
    path: `/internal/internalAssets/${id}`,
    method: 'DELETE',
  })
}
