import { light } from '@fortawesome/fontawesome-svg-core/import.macro'
import { DynamicTable, Icon, Text } from 'src/components/ui'
import { getOpcSubscriptionTableConfig } from './opc-ua-subs.config'
import { useParams } from 'react-router-dom'
import { useEffect } from 'react'
import { useNavigationContext } from 'src/contexts/navigation'
import { OpcUaSubscriptionNavigation } from './OpcUaSubsriptionNavigation'

const data = [
  {
    id: '1',
    displayName: 'Node 1',
  },
  {
    id: '2',
    displayName: 'Node 2',
  },
  {
    id: '3',
    displayName: 'Node 3',
  },
  {
    id: '4',
    displayName: 'Node 4',
  },
  {
    id: '5',
    displayName: 'Node 5',
  },
  {
    id: '6',
    displayName: 'Node 6',
  },
  {
    id: '7',
    displayName: 'Node 7',
  },
  {
    id: '8',
    displayName: 'Node 8',
  },
  {
    id: '9',
    displayName: 'Node 9',
  },
  {
    id: '10',
    displayName: 'Node 10',
  },
  {
    id: '11',
    displayName: 'Node 11',
  },
  {
    id: '12',
    displayName: 'Node 12',
  },
  {
    id: '13',
    displayName: 'Node 13',
  },
  {
    id: '14',
    displayName: 'Node 14',
  },
  {
    id: '15',
    displayName: 'Node 15',
  },
  {
    id: '16',
    displayName: 'Node 16',
  },
  {
    id: '17',
    displayName: 'Node 17',
  },
  {
    id: '18',
    displayName: 'Node 18',
  },
  {
    id: '19',
    displayName: 'Node 19',
  },
  {
    id: '20',
    displayName: 'Node 20',
  },
]

export function OpcUaSubscriptionPage(): JSX.Element {
  const { orgId, siteId, gatewayId, connectionId, subscriptionId } = useParams()
  if (!orgId || !siteId || !gatewayId || !connectionId || !subscriptionId)
    throw new Error('siteId, gatewayId or subscriptionId is not defined')

  const { setTitleComponent } = useNavigationContext()
  useEffect(() => {
    setTitleComponent(
      <OpcUaSubscriptionNavigation
        orgId={orgId}
        siteId={siteId}
        gatewayId={gatewayId}
        opcConnectionId={connectionId}
        subscriptionId={subscriptionId}
      />,
    )
    return () => setTitleComponent(null)
  }, [
    connectionId,
    gatewayId,
    orgId,
    siteId,
    subscriptionId,
    setTitleComponent,
  ])

  return (
    <div className="h-full p-s">
      <div className="flex h-full flex-col rounded-2xs bg-white px-s py-m">
        <div className="flex items-center gap-xl">
          <Icon
            icon={light('cloud-arrow-up')}
            className="-mr-m size-[40px] text-grey-text"
          />
          <div>
            <Text variant="description">Name</Text>
            <Text bold>Subscription 1</Text>
          </div>
          <div>
            <Text variant="description">Created On</Text>
            <Text bold>
              23 Apr 2020 | <span className="font-normal">21:43</span>
            </Text>
          </div>
          <div>
            <Text variant="description">Server Endpoint</Text>
            <Text bold>opc-tcp:/aadsflkmklmkmsdf</Text>
          </div>
        </div>
        <div className="flex flex-1 flex-col">
          <div className="grid h-full pt-m">
            <DynamicTable
              id="OpcSubscription"
              config={getOpcSubscriptionTableConfig({ siteId })}
              data={data}
            />
          </div>
        </div>
      </div>
    </div>
  )
}
