import { logError } from '../utility/logging'

type Fetcher = (r: Request) => Promise<Response>
type FetchWrapper = (f: Fetcher) => Fetcher

// wraps a fetch call with logging
const withLogging: FetchWrapper = fetcher => async req => {
  let res: Response

  const { method, url } = req
  const traceparent = req.headers.get('traceparent')

  try {
    res = await fetcher(req)
  } catch (error) {
    if (error instanceof Error) {
      logError(error, {
        source: 'api_fetch',
        url,
        method,
        traceparent,
      })
    }
    throw error
  }

  if (res.status >= 400) {
    const error = new Error(res.statusText)
    logError(error, {
      source: 'api_fetch',
      url: res.url,
      status: res.status,
      method,
      traceparent,
    })
  }

  return res
}

// our default fetch method
const fetcher = withLogging(fetch)
export default fetcher
