import apiFetch from './apiFetch'

// subset of SubscriptionDto
export interface CreditInfo {
  creditsUsed: number
  creditLimit: number | null
  activeModelCredits: number | null
}

export async function getCreditInfo(factory: string): Promise<CreditInfo> {
  const response = await apiFetch({
    path: '/subscription',
    query: { factory },
  })
  return response.json()
}
