import {
  useMutation,
  UseMutationResult,
  useQueryClient,
} from '@tanstack/react-query'
import { useSite } from 'src/contexts/site'
import * as api from 'src/services'
import { Asset } from 'src/types'
import { ASSETS_QUERY_KEY } from './assets.get'

interface CreateAssetRequest {
  name: string
  parentKey: number
}

// Merges the general asset and hierarchy asset into a single object
const mergeAsset = (
  asset: api.InternalAsset,
  ha: api.HierarchyAsset,
): Asset => ({
  ...ha,
  ...asset,
  assetId: asset.id,
  hierarchyId: ha.id,
})

export function useCreateAssetMutation(): UseMutationResult<
  Asset,
  unknown,
  CreateAssetRequest
> {
  const { id: factory } = useSite()
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async (request: CreateAssetRequest) => {
      // need to create the general asset first, due to requirement of key for the hierarchy call
      const asset = await api.createAsset(
        {
          name: request.name,
          alias: null,
        },
        factory,
      )

      const hierarchyAsset = await api.createHierarchyAsset(
        {
          viewType: 0,
          parentKey: request.parentKey,
          active: true,
          key: asset.key,
        },
        factory,
      )

      return mergeAsset(asset, hierarchyAsset)
    },
    onSuccess: () => {
      queryClient.invalidateQueries([ASSETS_QUERY_KEY, factory])
    },
  })
}
