import moment from 'moment-timezone'
import apiFetch from './apiFetch'
import { StreamlitApp, StreamlitSession } from './types'

export async function getStreamlitApps(
  factory: string,
): Promise<StreamlitApp[]> {
  const response = await apiFetch({ path: '/apps', query: { factory } })
  return response.json()
}

export async function startStreamlitSession(
  factory: string,
  slug: string,
): Promise<StreamlitSession> {
  const response = await apiFetch({
    path: `/apps/${slug}/start`,
    method: 'POST',
    query: {
      user_tz: moment.tz.guess(),
      factory,
    },
  })
  return response.json()
}

export async function verifyStreamlitSession(
  proxyPath: string,
): Promise<boolean> {
  const response = await apiFetch({
    path: `${proxyPath}/healthz`,
    method: 'GET',
  })

  return response.ok
}
