import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import { useNavigate } from 'react-router-dom'
import { Icon, Text } from 'src/components/ui'
import { useGateways } from 'gateway/api'

type Props = {
  orgId: string
  siteId: string
  gatewayId: string
  opcConnectionId: string
  subscriptionId: string
}
export function OpcUaSubscriptionNavigation({
  orgId,
  siteId,
  gatewayId,
  opcConnectionId,
}: Props): JSX.Element {
  const navigate = useNavigate()
  const { data: gateways } = useGateways(siteId)

  const baseRoute = `/settings/orgs/${orgId}/sites/${siteId}/gateways/${gatewayId}/opc-ua/${opcConnectionId}/subscriptions`

  const selectedGateway = gateways?.find(gateway => gateway.id === gatewayId)
  const selectedOpcConnection = selectedGateway?.opcConnections?.items?.find(
    c => c?.id === opcConnectionId,
  )

  return (
    <div className="flex items-center gap-xs">
      <Icon
        size="big"
        className="cursor-pointer"
        icon={regular('circle-chevron-left')}
        onClick={() => navigate(baseRoute)}
      />
      <div className="flex items-center">
        <Text variant="content-rubik" bold className="!mt-3xs inline">
          OPC-UA Connections | {selectedOpcConnection?.name ?? 'Connection'} |
        </Text>
        <Text variant="title" bold className="inline">
          {/* TODO: Fetch subscription and display name here */}
          &nbsp; Subscription Name
        </Text>
      </div>
    </div>
  )
}
