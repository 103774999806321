import Color from 'color'
import { useModelPrediction } from 'models/api'
import { useTagData } from 'tags/api'
import { useFilter } from 'src/contexts/filter'
import {
  Text,
  Card,
  TimePickerNavigation,
  TimeSeriesChart,
} from 'src/components/ui'
import { GqlFetchAnomalyFragment, GqlFetchModelFragment } from 'src/services'
import { Legend } from 'models/model'
import { theme } from 'tailwind.config'

interface OutputTagAndPredictionProps {
  model: GqlFetchModelFragment
  // the `current` anomaly to display
  anomaly?: GqlFetchAnomalyFragment
}

export function OutputTagAndPrediction({
  model,
  anomaly,
}: OutputTagAndPredictionProps): JSX.Element {
  const { filter } = useFilter()
  const modelPrediction = useModelPrediction({
    id: model.id,
  })
  const tagData = useTagData({ id: model.tag.tagName })

  const legend: Record<string, string> = {
    'Actual value': '#726ADF',
    Prediction: theme.colors.prediction,
  }
  if (filter) {
    legend['Filtered out'] = theme.colors.filter
  }
  if (anomaly) {
    legend['Current anomaly'] = Color(theme.colors.danger).alpha(0.6).toString()
  }
  legend.Anomaly = Color(theme.colors.danger).alpha(0.3).toString()

  return (
    <Card className="col-span-2">
      <div className="grid grid-flow-col items-center">
        <Text variant="title" bold>
          Output tag and prediction
        </Text>
        <div className="mb-xs">
          <TimePickerNavigation />
        </div>
      </div>
      <div className="ml-[40px] flex justify-between">
        <Text>
          <span title={model.tag.tagName}>
            {model.tag.displayName || model.tag.tagName}
          </span>
          {model.tag.description && (
            <span>, description: {model.tag.description}</span>
          )}
          {model.tag.engUnit && <span>, unit: {model.tag.engUnit}</span>}
        </Text>
        <Legend data={legend} />
      </div>
      <TimeSeriesChart
        tooltip
        isPending={modelPrediction.isLoading || tagData.isLoading}
        data={[
          {
            name: 'Actual value',
            data: tagData.data,
            color: '#726ADF',
          },
          {
            name: 'Prediction',
            data: modelPrediction.data ?? [],
            color: theme.colors.prediction,
          },
        ]}
        anomaly={anomaly}
      />
    </Card>
  )
}
