import { UseQueryResult, useQuery } from '@tanstack/react-query'
import {
  GqlFetchAnomalyFragment,
  fetchAnomaly as fetchAnomalyRequest,
} from 'src/services'

export const ANOMALY = 'anomaly'

export function useAnomaly(
  factory: string,
  anomaly: string,
): UseQueryResult<GqlFetchAnomalyFragment, Error> {
  return useQuery({
    queryKey: [ANOMALY, anomaly],
    queryFn: async () => {
      return fetchAnomaly(factory, anomaly)
    },
  })
}

async function fetchAnomaly(
  factory: string,
  anomaly: string,
): Promise<GqlFetchAnomalyFragment> {
  const response = await fetchAnomalyRequest(factory, anomaly)
  const data = response.factory?.anomaly
  if (!data) {
    throw new Error('Anomaly not found')
  }
  return data
}
