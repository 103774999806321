import { useState, useEffect, useCallback, ReactNode } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { isEqual } from 'lodash'
import {
  ChartLayoutConfig,
  ChartOptions,
  ChartSize,
  isChartSize,
} from 'src/types/chartTypes'
import { useUrlQuery, getSingle } from 'src/utility'
import { useFactoryTrendViews } from 'trend/api'
import { ChartsContext, ChartsContextType } from './ChartsContext'
import { encodeCharts, parseCharts } from './chartsEncoding'
import * as Charts from './charts.utils'

const validateSize = (size: any): ChartSize => {
  if (isChartSize(size)) {
    return size
  }
  return 'medium' // default value
}

const validateIsFullWidth = (isFullWidth: any): boolean => {
  return isFullWidth === 'true'
}

interface ChartsProviderProps {
  children: ReactNode
  id: string
}

export const ChartsProvider = ({
  children,
  id,
}: ChartsProviderProps): JSX.Element => {
  const { query, update, generateQueryString } = useUrlQuery()
  const { trendViewId } = useParams()
  const { data: trendViewsData } = useFactoryTrendViews()
  const navigate = useNavigate()

  const currentView = trendViewsData?.find(view => view.id === trendViewId)

  const getConfigFromUrl = useCallback((): ChartLayoutConfig => {
    return {
      selectedSize: validateSize(query.size),
      isFullWidth: validateIsFullWidth(query.isFullWidth),
    }
  }, [query.size, query.isFullWidth])

  const getInitialConfig = useCallback((): ChartLayoutConfig => {
    // Check for config in the URL
    if (query.size || query.isFullWidth) {
      return getConfigFromUrl()
    }
    // Otherwise, get config from session storage or use default
    const configFromSession = sessionStorage.getItem(`${id}-config`)
    return configFromSession
      ? JSON.parse(configFromSession)
      : {
          selectedSize: validateSize(undefined),
          isFullWidth: validateIsFullWidth(undefined),
        }
  }, [query.size, query.isFullWidth, id, getConfigFromUrl])

  const [isViewChanged, setIsViewChanged] = useState<boolean>(false)

  const [config, setConfig] = useState<ChartLayoutConfig>(getInitialConfig())

  useEffect(() => {
    setConfig(getInitialConfig())
  }, [getInitialConfig])

  const getInitialCharts = useCallback((): ChartOptions[] => {
    // Check for charts in the URL
    const chartsFromURL = getSingle(
      Array.isArray(query.charts) ? query.charts.join(',') : query.charts,
    )
    // If charts are in the URL, parse them
    if (chartsFromURL) {
      return chartsFromURL ? parseCharts(chartsFromURL) : []
    }
    // Otherwise, get charts from session storage or use empty array
    const chartsFromSession = sessionStorage.getItem(`${id}-charts`)
    return chartsFromSession ? parseCharts(chartsFromSession) : []
  }, [query.charts, id])

  const [charts, setCharts] = useState<ChartOptions[]>(getInitialCharts())

  const loadViewFromId = useCallback(() => {
    if (currentView) {
      const { data } = currentView
      const { charts, config } = JSON.parse(data)
      setCharts(charts)
      setConfig(config)
    }
  }, [currentView])

  useEffect(() => {
    setCharts(getInitialCharts())
  }, [getInitialCharts])

  useEffect(() => {
    // Clear query params only if charts are present
    // This is to get rid of old url based format
    if (query.charts) {
      update({
        size: undefined,
        isFullWidth: undefined,
        charts: undefined,
      })
    }
  }, [query.charts, update])

  useEffect(() => {
    loadViewFromId()
  }, [loadViewFromId])

  useEffect(() => {
    const storedView = sessionStorage.getItem(`${id}-id`)
    if (storedView && !trendViewId) {
      const query = generateQueryString({
        charts: charts?.length ? encodeCharts(charts) : undefined,
        size: config.selectedSize,
        isFullWidth: config.isFullWidth,
      })
      navigate(`${storedView}?${query}`)
      return
    }
    // check if view has changed
    if (trendViewId && currentView) {
      const { data } = currentView
      const { charts: viewCharts, config: viewConfig } = JSON.parse(data)
      if (isEqual(viewCharts, charts) && isEqual(viewConfig, config)) {
        setIsViewChanged(false)
      } else {
        setIsViewChanged(true)
      }
    }
    // Update session storage when charts or config change
    if (trendViewId) sessionStorage.setItem(`${id}-id`, trendViewId)
    else sessionStorage.removeItem(`${id}-id`)
    sessionStorage.setItem(`${id}-charts`, JSON.stringify(charts))
    sessionStorage.setItem(`${id}-config`, JSON.stringify(config))
  }, [
    charts,
    config,
    id,
    trendViewId,
    currentView,
    generateQueryString,
    navigate,
  ])

  useEffect(() => {
    update({
      size: config.selectedSize,
      isFullWidth: config.isFullWidth,
    })
  }, [config, update])

  const addChart = useCallback((chart: ChartOptions) => {
    setCharts(charts => Charts.addChart(charts, chart))
  }, [])

  const removeChart = useCallback((id: number) => {
    setCharts(charts => Charts.removeChart(charts, id))
  }, [])

  const setChart = useCallback((id: number, chart: ChartOptions) => {
    setCharts(charts => Charts.updateChart(charts, id, chart))
  }, [])

  const setChartOrder = useCallback((newOrder: number[]) => {
    setCharts(charts => Charts.reorder(charts, newOrder))
  }, [])

  const generateChartUrl = useCallback(() => {
    const url = generateQueryString({
      charts: charts?.length ? encodeCharts(charts) : undefined,
      size: config.selectedSize,
      isFullWidth: config.isFullWidth,
    })
    return `${window.location.origin}${window.location.pathname}?${url}`
  }, [config, charts, generateQueryString])

  const loadClearView = useCallback(() => {
    if (trendViewId) {
      navigate('..')
    }
    sessionStorage.removeItem(`${id}-id`)
    sessionStorage.removeItem(`${id}-charts`)
    sessionStorage.removeItem(`${id}-config`)
    setCharts([])
    setConfig({
      selectedSize: validateSize(undefined),
      isFullWidth: validateIsFullWidth(undefined),
    })
    setIsViewChanged(false)
  }, [id, navigate, trendViewId])

  const contextValue: ChartsContextType = {
    chartConfig: config,
    charts,
    isViewChanged,
    addChart,
    removeChart,
    setChart,
    setChartOrder,
    setChartConfig: setConfig,
    generateChartUrl,
    resetViewChanges: loadViewFromId,
    loadClearView,
  }

  return (
    <ChartsContext.Provider value={contextValue}>
      {children}
    </ChartsContext.Provider>
  )
}
